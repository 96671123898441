<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>laptop-help-message</title>
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="21.75"
      y1="15.75"
      x2="21.75"
      y2="18.75"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M2.25,18.75V8.25a1.5,1.5,0,0,1,1.5-1.5H7.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.584,18.75a2.976,2.976,0,0,1-5.168,0H.75v1.5a3,3,0,0,0,3,3h16.5a3,3,0,0,0,3-3v-1.5Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.659,1.011a6.363,6.363,0,0,0-7.582,8.8L9.013,14l4.723-1.29a6.293,6.293,0,0,0,1.319.567,6.392,6.392,0,0,0,3.6-12.265Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.007,5.657a1.875,1.875,0,1,1,1.875,1.875"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M16.882,9.782a.375.375,0,1,0,.375.375.374.374,0,0,0-.375-.375h0"
    />
  </svg>
</template>
